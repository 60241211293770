import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Services.css';

const AyraEduc = () => {

    const horizontal ={
        x:0, 
        opacity: 1, 
        transition:{type: 'spring', duration: 2,bounce: 0.3}
      }

    return (
        <>
            <div className="AyraEduc" id='AyraEduc'>
                <div className="container">
                    <div className="heading">
                        <p className="heading-sub-text">Ayra</p>
                        <p className='heading-text'>Education</p>
                    </div>
                    <div className="education-box">
                        <motion.div initial={{x: '100%', opacity: 0}} whileInView={horizontal}>
                            <p className="pilates-text">
                                Temos a missão de disseminar conhecimento em saúde baseado em ciência.
                                <br /><br />
                                Se você tem interesse, fique atento para mais informações em breve. 
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AyraEduc;