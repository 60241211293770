import React from "react";
import "../../styles/Navbar.css";
import { AiOutlineWoman } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { MdOutlineSportsKabaddi } from "react-icons/md";
import { RiHealthBookLine } from "react-icons/ri";
import { BiHealth } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa";
import { MdPersonPin } from "react-icons/md";
import { Link } from "react-scroll";
import ProfileImg from "../../images/ayracarelogobege.png";
import NavLinks from "./NavLinks";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

const navVariants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.5,
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.56,
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const Navbar = ({ nav, handleNav, closeNav }) => {

  // Function to delay closing the navbar for smooth scrolling
  const delayedCloseNav = () => {
    setTimeout(() => {
      closeNav();
    }, 300); // Delay of 300ms to allow smooth scroll to start
  };

  return (
    <AnimatePresence>
      <motion.nav
        initial={{ width: "0" }}
        animate={nav ? { width: "300px" } : { width: "0", transition: { delay: 1 } }}
        className={nav ? "navbar active" : "navbar"}
        aria-label="Main Navigation" // ARIA label for screen readers
      >
        <motion.div
          initial="hidden"
          whileInView={nav ? "visible" : "hidden"}
          variants={navVariants}
          exit="hidden"
          className="navbar-container"
        >
          <div className="top-details">
            <div className="img__cover">
              <img src={ProfileImg} alt="Ayra Care Logo" className="profile-pic-small" />
            </div>
          </div>
          <ul className="mid-details">
            {/* Links to sections with delayed navbar close */}
            <div className="mid-links-disabled">
              <FaRegAddressCard className="mid-icon" />
              <li className="mid-link">Area Pessoal</li>
              <span className="coming-soon">Em Breve</span>
            </div>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="about"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Quem Somos?"
            >
              <BsQuestionCircle className="mid-icon" />
              <li className="mid-link">Sobre nós</li>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="ayraPilates"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Aprenda mais sobre Ayra Pilates"
            >
              <MdOutlineSportsKabaddi className="mid-icon" />
              <li className="mid-link">Ayra Pilates</li>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="ayraFisio"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Aprenda mais sobre Ayra Physio"
            >
              <BiHealth className="mid-icon" />
              <li className="mid-link">Ayra Physio</li>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="ayraWomenH"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Explore Ayra Care: Saude da Mulher"
            >
              <AiOutlineWoman className="mid-icon" />
              <li className="mid-link">Ayra Women's Health</li>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="ayraEduc"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Discover Ayra Education"
            >
              <RiHealthBookLine className="mid-icon" />
              <li className="mid-link">Ayra Education</li>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              to="contact"
              className="mid-links"
              onClick={delayedCloseNav}
              aria-label="Contact Us"
            >
              <MdPersonPin className="mid-icon" />
              <li className="mid-link">Contactos</li>
            </Link>
            <div className="socials">
              <NavLinks handleNav={handleNav}></NavLinks>
            </div>
            <div className="bottom-copy"> 
              <span className="copy">
                <small className="copyright">
                  <a
                    href={`${process.env.PUBLIC_URL}/humans.txt`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`© ${new Date().getFullYear()} All rights reserved. `}
                  </a>
                </small>
              </span>
            </div>
          </ul>
        </motion.div>
      </motion.nav>
    </AnimatePresence>
  );
};

export default Navbar;
