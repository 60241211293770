import React from 'react';
import { motion } from 'framer-motion';
import PhisioImg from '../images/FisioImage3.png'
import PhisioImgTwo from '../images/FisioImage2.png'
import FisioBanner from '../images/FisioBanner.png'
import '../styles/Services.css';
import 'animate.css';

const AyraFisio = () => {
    const heroVariants = {
		hidden: {
			opacity: 0,
			y: "-50%",
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

    return (
        <>
            <div className="AyraFisio" id='AyraFisio'>
                <div className="container">
                    <div className="pilates-banner">
                        <motion.div className="pilates-bannerimg">
                            <img src={FisioBanner} alt="FisioBanner" />
                        </motion.div>
                    </div>
                    <div className="services-box">
                        <motion.div 
                            variants={heroVariants}
                            initial='hidden'
                            whileInView='visible'
                            className='pilates-img'
                        >
                            <img src={PhisioImg} alt="Phisioterapy" />
                            <img src={PhisioImgTwo} alt="Phisioterapy" />
                        </motion.div>
                        <motion.div>
                            <p className="pilates-text">
                            A fisioterapia é uma área da saúde focada na prevenção, tratamento e reabilitação de disfunções físicas. Utilizando técnicas como exercícios terapêuticos, 
                            terapia manual e recursos físicos (eletroterapia, termo terapia) a fisioterapia ajuda a restaurar a mobilidade, reduzir a dor, e melhorar a qualidade de vida 
                            dos pacientes.
                            <br /><br />
                            Ela é indicada para diversas condições, como lesões esportivas, dores crônicas,  e recuperação pós-cirúrgica. Além de tratar, a fisioterapia também previne 
                            complicações, ajudando os pacientes a manterem-se ativos e saudáveis.
                            <br /><br />
                            Na Ayra, oferecemos o tratamento fisioterapêutico ideal para si.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AyraFisio;