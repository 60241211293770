import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Services.css';
import 'animate.css';
import WomenImg from '../images/WomenImage3.png';
import WomenImgTwo from '../images/WomenImage2.png';
import WomenBanner from '../images/WomenBanner.png';


const AyraWomenH = () => {
    const heroVariants = {
		hidden: {
			opacity: 0,
			y: "-50%",
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

    return (
        <>
            <div className="AyraWomenH" id='AyraWomenH'>
                <div className="container">
                    <div className="pilates-banner">
                        <motion.div className="pilates-bannerimg">
                            <img src={WomenBanner} alt="WomenBanner" />
                        </motion.div>
                    </div>
                    <div className="services-box">
                        <motion.div 
                            variants={heroVariants}
                            initial='hidden'
                            whileInView='visible'
                            className='pilates-img'
                        >
                            <img src={WomenImg} alt="Women's Health" />
                            <img src={WomenImgTwo} alt="Women's Health" />
                        </motion.div>
                        <motion.div>
                            <p className="pilates-text">
                                A fisioterapia na saúde da mulher é uma área que dedica-se ao cuidado abrangente da mulher em todas as etapas da vida: desde a adolescência 
                                até a maturidade plena. Muito procurada durante a gravidez, ela prepara o corpo para passar por este período de inúmeras mudanças e desafios.
                                <br /><br />
                                Assim, a fisioterapeuta que cuida da saúde da mulher dedica-se a promover saúde ou tratar patologias nos mais diversos contextos, como: 
                                a preparação do parto, o pós-parto, em casos de gestão do controle urinário e fecal, em condições cirúrgicas da mama, menopausa, 
                                alterações músculo esqueléticas relacionadas à pelve, disfunções sexuais e quadros de dor.
                                <br /><br />
                                Muitos sintomas comuns são, às vezes, negligenciados, como a perda involuntária de urina. Sabemos que isso não é normal e priorizamos 
                                o tratamento com base em evidências científicas rigorosas.
                                <br /><br />
                                Por isso, como especialistas em Saúde da Mulher, estamos prontas para proporcionar cuidados personalizados com toda a atenção e carinho que você merece.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AyraWomenH;