import React, { useState, useEffect } from 'react';
import "../styles/Contact.css";
import { motion } from "framer-motion";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiTwotoneMail } from "react-icons/ai";
import ProfileImg from "../images/FisioBanner.png";

const Contact = () => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (openModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [openModal]);

    const fade = {
        opacity: 1,
        transition:{
            duration: 1.5
        }
    }

    const verticalLeft = {
        opacity: 1,
        y: 0,
        transition:{
            duration: 1.5
        }
    }

    const contactVariants = {
		hidden: {
			opacity: 0,
			x: "-50%",
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				duration: 1.4,
			},
		},
	};

    const handleEmailClick = () => {
        window.location.href = 'mailto:mariaghizellini@gmail.com';
    };

    const handleWhatsAppClick = () => {
        setOpenModal(true);
    };

    return (
        <>
            <div className="contact" id='contact'>
                <div className="container">
                    <motion.div className="heading" initial={{opacity: 0}} whileInView={fade} viewport={{ once: true }}>
                        <p className="heading-sub-text">Marque o seu atendimento</p>
                    </motion.div>
                    <div className="contact-box">
                        <motion.div className="left-box" initial={{opacity: 0, y: '-50px'}} whileInView={verticalLeft}>
                            <div className="contact-heading">
                                <p>Para saber mais entre em contacto.</p>
                            </div>
                            <div className="contact-hello">
                                <p> Moramos longe? Não tem problema nenhum.
                                    <br/>Também oferecemos os nossos serviços na modalidade online.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div className="right-box" initial={{ opacity: 0, y: '50px' }} whileInView={verticalLeft}>
                            <motion.span
                                onClick={handleEmailClick} // Handle email click
                                variants={contactVariants}
                                initial='hidden'
                                whileInView='visible'>
                                <button className='contact-Whats' type="button">
                                    <AiTwotoneMail /> Email
                                </button>
                            </motion.span>
                            <motion.span
                                onClick={handleWhatsAppClick} // Handle WhatsApp click
                                variants={contactVariants}
                                initial='hidden'
                                whileInView='visible'>
                                <button className='contact-Whats' type="button">
                                    <AiOutlineWhatsApp /> WhatsApp
                                </button>
                            </motion.span>
                            {openModal && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <span className="close" onClick={() => setOpenModal(false)}>&times;</span>
                                        <div className='top-details'>
                                            <div className='img__cover'>
                                                <img src={ProfileImg} alt='Main' className='profile-pic-small' />
                                            </div>
                                        </div>
                                        <h2>Contacto WhatsApp Business</h2>
                                        <p>Numero de telefone: <b>(+351) 1234567</b></p>
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
